<!--
 * @Author: PC
 * @Date: 2024-10-15 17:20:17
 * @LastEditTime: 2024-11-02 18:14:20
 * @Description: 嘉宾卡片列表
-->
<template>
    <div class="card-component" >
        <div class="card-component_inner" :style="{opacity: stop ? 0.5 : 1}">
            <div v-for="(card, index) in cards" :key="index" class="card" :style="getCardStyle(index)" @click="handleDetails(card)">
                <img :src="card?.avatarBG" class="avatar_bg" ref="cardAvatarBg" />
                <div class="card_inner" ref="cardComponent">
                    <div class="avatar_warpper">
                        <img :src="card?.avatar" class="avatar" data-hover="true" />
                    </div>

                    <div class="info">
                        <div>
                            <div class="label gradient_text">{{ card?.label?.[$i18n.locale] }}</div>
                        </div>
                        <div>
                            <div class="name gradient_text">{{ card?.name?.[$i18n.locale] }}</div>
                        </div>
                        <div class="title">
                            <div v-for="(item, index) in card?.title?.[$i18n.locale] || []" :key="index" class="title_i">{{ item }}</div>
                        </div>
                    </div>
                </div>
                <transition name="fade">
                    <div v-show="showBtn(index)" v-if="$VUE_APP_TREE_SHOW === 'true'">
                        <div class="into_button" data-hover="true" @click.stop="$emit('intoNext')">{{  $t("intoTree") }}</div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import { gsap } from "gsap";

export default {
    props: {
        stop: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            position: 0, // 初始位置设置为第一个卡片的位置
            cardDistance: 400, // Z轴距离
            xOffset: 380, // X轴偏移量
            cards: [
                {
                    id: 0,
                    avatar: require("@/assets/images/home/scene2/guest/guest0.png"),
                    card: require("@/assets/images/home/scene2/guest/guest0_card.png"),
                    avatarBG: require("@/assets/images/home/scene2/guest/guest0_bg.png"),
                    label: {
                        zh: "攻克量子世界制高点",
                        en: "", //TODO:还没有英文标语
                    },
                    name: {
                        zh: "薛其坤",
                        en: "Qi-Kun Xue",
                    },
                    title: {
                        zh: ["国家最高科学技术奖获得者", "中国科学院院士", "南方科技大学校长", "清华大学教授"],
                        en: [
                            "Recipient of the State Preeminent Science and Technology Award",
                            "Academician of the Chinese Academy of Sciences",
                            "President of Southern University of Science and Technology",
                            "Professor at Tsinghua University",
                        ],
                    },
                    introduction: {
                        zh: "薛其坤是国际知名凝聚态物理学家，他所创新发现的“量子反常霍尔效应”和“界面高温超导”攻克了量子物理的世界难题。薛其坤也是国家自然科学奖一等奖和国际凝聚态物理领域最高奖巴克利奖得主。",
                        en: `Professor Xue stands as a globally acclaimed physicist specializing in condensed matter physics. His groundbreaking discoveries, including the "Quantum Anomalous Hall Effect" and "Interface High-Temperature Superconductivity," have addressed some of the most complex challenges within the realm of quantum physics. In recognition of his contributions, Xue Qikun has been honored with the State Preeminent Science and Technology Award and the Oliver E. Buckley Condensed Matter Physics Prize, which is considered the pinnacle of achievement in the field of condensed matter physics internationally.`,
                    },
                    // video: {
                    //     url: "l0013ubqehv",
                    // },
                },
                {
                    id: 1,
                    avatar: require("@/assets/images/home/scene2/guest/guest1.png"),
                    card: require("@/assets/images/home/scene2/guest/guest1_card.png"),
                    avatarBG: require("@/assets/images/home/scene2/guest/guest1_bg.png"),
                    label: {
                        zh: "为人类预演时空旅行的人",
                        en: "", //TODO:还没有英文标语
                    },
                    name: {
                        zh: "基普·索恩",
                        en: "Kip S. Thorne",
                    },
                    title: {
                        zh: ["2017年诺贝尔物理学奖得主", "加州理工学院费曼理论物理学荣誉教授", "电影《星际穿越》科学顾问和执行制片人"],
                        en: [
                            "Laureate of the 2017 Nobel Prize in Physics",
                            "Feynman Professor of Theoretical Physics. Emeritus，California Institute of Technology",
                            "Science advisor and executive producer of the film Interstellar",
                        ],
                    },
                    introduction: {
                        zh: "作为理论物理学界的领军人物，索恩教授对引力波观测有着重大贡献。他拓展了物理学的边界，探索了可穿越虫洞作为时间机器的可能性，并为多部科幻作品提供了科学指导，其中包括克里斯托弗·诺兰的著名电影《星际穿越》和《信条》。",
                        en: `Professor Thorne is one of the world's top theoretical physicists and cofounded the LIGO Project. He is known for his contributions in gravitational physics and the observation of gravitational waves, and was awarded the Nobel Prize in Physics in 2017.  Thorne initiated physicists’ research on traversable wormholes as time machines and has been a consultant for scientific novels and movies, most notably for the Christopher Nolan film Interstellar.`,
                    },
                    // video: {
                    //     url: "g1400coayyw",
                    // },
                },
                {
                    id: 2,
                    avatar: require("@/assets/images/home/scene2/guest/guest2.png"),
                    card: require("@/assets/images/home/scene2/guest/guest2_card.png"),
                    avatarBG: require("@/assets/images/home/scene2/guest/guest2_bg.png"),
                    label: {
                        zh: "在地球深处捕捉宇宙幽灵",
                        en: "", //TODO:还没有英文标语
                    },
                    name: {
                        zh: "刘江来",
                        en: "Jiang-Lai Liu",
                    },
                    title: {
                        zh: ["上海交通大学李政道研究所特聘教授、副所长", "PandaX 暗物质实验首席科学家", "科学探索奖获奖人"],
                        en: [
                            "Distinguished Professor",
                            "Deputy Director of the Tsung-Dao Lee Institute (TDLI) and Chief Scientist of the PandaX experiment for dark matter detection at Shanghai Jiao Tong University",
                            "Recipient of the XPLORER PRIZE",
                        ],
                    },
                    introduction: {
                        zh: "刘江来教授长期致力于粒子、原子核与天体物理交叉领域研究。他目前是PandaX暗物质实验项目首席科学家，组织科研团队在锦屏地下实验室进行暗物质探测实验。团队成功研制三代液氙探测器，取得一系列国际领先的暗物质测量结果。",
                        en: `Prof. Liu has been deeply engaged in cutting-edge research at the intersection of particle physics, nuclear physics, and astrophysics. He is currently the spokesperson for the Particle and Astrophysical Xenon Detector (PandaX) project, leading the collaboration to study dark matter at the China Jinping Underground Laboratory. The collaboration has successfully developed three generations of liquid xenon detectors, achieving world-leading results in understanding dark matter properties.`,
                    },
                },
                {
                    id: 3,
                    avatar: require("@/assets/images/home/scene2/guest/guest3.png"),
                    card: require("@/assets/images/home/scene2/guest/guest3_card.png"),
                    avatarBG: require("@/assets/images/home/scene2/guest/guest3_bg.png"),
                    label: {
                        zh: "发现大脑内置GPS系统的人",
                        en: "", //TODO:还没有英文标语
                    },
                    name: {
                        zh: "梅-布里特·莫泽",
                        en: "May-Britt Moser",
                    },
                    title: {
                        zh: ["2014年诺贝尔生理学或医学奖得主", "挪威科技大学系统神经科学卡夫利研究所神经科学教授"],
                        en: [
                            "Laureate of the 2014 Nobel Prize in Medicine or Physiology",
                            "Professor of Neuroscience，Kavli Institute of Systems Neuroscience at Norwegian University of Science and Technology",
                        ],
                    },
                    introduction: {
                        zh: "<p>莫泽教授是神经科学领域的杰出学者，她与丈夫爱德华-莫泽共同发现了大脑内嗅皮层中的网格细胞，可作为大脑定位系统（GPS）的重要空间尺度。莫泽教授还开创性地发现了大脑定位系统中的其它功能细胞，并揭示了这些细胞在神经网络中的组织方式。</p><p>2014年，梅-布里特·莫泽获得诺贝尔生理学或医学奖。目前，她致力于借助新技术同步记录成千上万个脑细胞的活动，旨在全面解密大脑智力背后的底层算法。</p>",
                        en: `<p>Professor M-B Moser is one of the world's top international neuroscientists. She co-discovered the grid cells in the entorhinal cortex of the brain, together with Edvard Moser. Grid cells provide the metric to the brain’s position system (GPS). M-B Moser is known for her contributions in discovering other functional cells in the positioning system of the brain as well as the organization of these cells in neural networks.</p>
                       <p>She was awarded the Nobel Prize in Medicine or Physiology in 2014. She is now using new technology to record the activity from thousands of brain cells at the same time so that we can understand broadly the algorithms underlying intellectual functions in general.</p> `,
                    },
                },
                {
                    id: 4,
                    avatar: require("@/assets/images/home/scene2/guest/guest4.png"),
                    card: require("@/assets/images/home/scene2/guest/guest4_card.png"),
                    avatarBG: require("@/assets/images/home/scene2/guest/guest4_bg.png"),
                    label: {
                        zh: "在芯片里复刻人体器官的人",
                        en: "", //TODO:还没有英文标语
                    },
                    name: {
                        zh: "唐纳德·英格伯",
                        en: "Donald E. Ingber",
                    },
                    title: {
                        zh: ["哈佛大学生物启发工程怀斯研究所所长", "哈佛大学教授", "美国国家医学院院士", "美国国家工程院院士", "美国国家发明家科学院院士"],
                        en: [
                            "Founding Director of the Wyss Institute for Biologically Inspired Engineering",
                            "Professor at Harvard University",
                            "National Academy of Medicine",
                            "National Academy of Engineering",
                            "National Academy of Inventors",
                        ],
                    },
                    introduction: {
                        zh: "英格伯教授率领的团队致力于开创性的仿生技术研究，持续推动医疗健康领域的创新和可持续发展。他的研究成果推动了机械生物学、细胞结构、癌症、组织工程、系统生物学、纳米生物技术和转化医学等多个关键领域实现显著突破。",
                        en: "Ingber is a pioneer in the field of biologically inspired engineering, and at the Wyss Institute, he currently leads scientific and engineering teams that cross a broad range of disciplines to develop breakthrough bioinspired technologies to advance healthcare and to improve sustainability. His work has led to major advances in mechanobiology, cell structure, cancer, tissue engineering, systems biology, nanobiotechnology, and translational medicine. Through his work, Ingber also has helped to break down boundaries between science, art and design.",
                    },
                },
            ],
        };
    },

    mounted() {
        setTimeout(() => {
            document.addEventListener("wheel", this.throttledOnWheel);
        }, 800);
        setTimeout(() => {
            document.addEventListener("mousemove", this.handleMouseMove);
        }, 2000);
    },

    beforeDestroy() {
        // 移除事件监听
        document.removeEventListener("wheel", this.throttledOnWheel);
        document.removeEventListener("mousemove", this.handleMouseMove);
    },

    methods: {
        // 滚轮事件处理：模拟前进或后退
        throttledOnWheel: _.throttle(
            function (event) {
                if (this.stop) return;
                const direction = event.deltaY > 0 ? 1 : -1; // 向下滚动时方向为 1
                const newPosition = this.position + direction * 400; // 向下滚动卡片向前移动，向上滚动卡片向后移动
                const minPosition = 0; // 限制在最前面
                const maxPosition = (this.cards.length - 1) * this.cardDistance; // 限制在最后一张卡片的位置
                // 限制滚动范围
                if (newPosition >= minPosition && newPosition <= maxPosition) {
                    this.position = newPosition;
                    this.$emit("start", direction);
                    this.$nextTick(() => {
                        const isLast = this.showBtn(this.cards.length - 1);
                        this.$emit("isLast", isLast);
                    })
                } else {
                    this.$emit("onWheel", direction);
                }
            },
            1000,
            { trailing: false }
        ),

        handleMouseMove(e) {
            if (this.stop) return;
            const x = e.clientX / window.innerWidth - 0.5;
            const y = e.clientY / window.innerHeight - 0.5;
            gsap.to(this.$refs.cardComponent, {
                x: x * 65, // 根据鼠标位置调整 x 位移
                y: y * 65, // 根据鼠标位置调整 y 位移
                duration: 0.2,
                ease: "power1.out",
            });
            gsap.to(this.$refs.cardAvatarBg, {
                x: x * 35, // 根据鼠标位置调整 x 位移
                y: y * 35, // 根据鼠标位置调整 y 位移
                duration: 0.1,
                ease: "power1.out",
            });
        },

        // 获取卡片的样式
        getCardStyle(index) {
            // 将索引大的卡片 Z 轴位置设置为更近
            const zPos = -(index * this.cardDistance) + this.position;

            // 确保第一个卡片在右侧，后续按奇偶分布
            const side = index % 2 === 0 ? 1 : -1; // 偶数在右，奇数在左

            const xPos = zPos <= 0 ? side * this.xOffset : side * (this.xOffset + 800); // X 轴位置偏移
            const xPos2 = side === -1 ? xPos - 410 : xPos;
            const xPos3 = index === this.cards.length - 1 ? "-50%" : `${xPos2}px`;

            const show = zPos <= 0 && zPos >= -this.cardDistance ? 1 : 0;

            return {
                transform: `translate3d(${xPos3}, -50%, ${zPos}px) scale(${show})`,
                "z-index": 90 - index,
                opacity: show,
            };
        },

        showBtn(index) {
            // return true
            if (index !== this.cards.length - 1) return false;
            return this.position === this.cardDistance * (this.cards.length - 1);
        },

        handleDetails(card) {
            this.$emit("viewDetails", card);
        },
    },
};
</script>

<style lang="less">
.card-component {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    

    .card-component_inner {
        width: 100%;
        height: 100%;
        perspective: 500px;
        transform: scale(0.6);
        transform-origin: center;
        position: relative;
        transition: opacity 0.3s;
        @media screen and (min-width: 1200px) {
            transform: scale(0.7);
        }
        @media screen and (min-width: 1366px) {
            transform: scale(0.8);
        }
        @media screen and (min-width: 1920px) {
            transform: scale(1);
        }
        .card {
            position: absolute;
            width: 412px;
            left: 50%;
            top: 50%;
            transform-origin: center;
            transition: transform 2s ease, opacity 1.2s;
            .avatar_warpper {
                width: 412px;
                height: 412px;
                position: relative;
                opacity: 1;
                transition: all 0.5s;

                .avatar {
                    width: 100%;
                    height: 100%;
                }
                .avatar_filter {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    transition: all 1s;
                    // &:hover {
                    //     opacity: 1;
                    //     transform: rotate(-45deg);
                    // }
                }
            }

            .avatar_bg {
                position: absolute;
                top: 140px;
                left: 140px;
                width: 560px;
                height: 560px;
                z-index: -1;
            }
            .info {
                margin-top: 36px;
                padding-left: 32px;
                
                .label {
                    font-size: 18px;
                    font-weight: 600;
                }
                .name {
                    font-size: 36px;
                    font-weight: 600;
                }

                .title {
                    padding-top: 12px;
                    .title_i {
                        color: #f6f6f6cc;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .into_button {
        padding: 1px;
        width: 275px;
        height: 45px;
        position: absolute;
        bottom: -88px;
        left: 50%;
        transform: translateX(-50%);
        background: url("~@/assets/images/home/scene2/into_btn_bg.png") no-repeat center;
        background-size: 100% 100%;
        overflow: hidden;
        transition: all 0.3s;
        z-index: 103;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 18px;
        animation: into_button 2s ease forwards;
        animation-delay: 1.8s;
        &:hover {
            opacity: 0.6;
        }
    }
}

@keyframes into_button {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
